import styled from 'styled-components';

const WavesContainer = styled.svg`
  width: 100%;
  z-index: -10;
  bottom: 0;
  display: block;
  ${props => {
    if (props.position === 'sticky') {
      return 'position: sticky; position: -webkit-sticky;'
    } else {
      return `position:${props.position};`
    }
  }
  };
  ${props => props.backgroundColor ? `background-color: ${props.backgroundColor}` : ''};
  box-sizing: border-box;
`

export const Waves = (props) => {
    const {color} = props;
    return (
      null
        // <WavesContainer {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        //     <path fill={color} fillOpacity="1"
        //           d="M0,96L40,112C80,128,160,160,240,154.7C320,149,400,107,480,101.3C560,96,640,128,720,144C800,160,880,160,960,186.7C1040,213,1120,267,1200,245.3C1280,224,1360,128,1400,80L1440,32L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z"></path>
        // </WavesContainer>
    )
}