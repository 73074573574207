import {
    ErrorText,
    InputGroup,
    InputLabel,
    SignInInput,
    SignUpAndInForm,
    SignUpContainer,
    SubmitButton
} from '../input-components';
import {useForm} from 'react-hook-form';
import {useState} from 'react';
import {useFirestore, useUser} from 'reactfire';
import {Redirect, useLocation} from 'react-router-dom';


export const CreateAccount = (props) => {
    const {register, handleSubmit, formState: {errors}} = useForm();
    const location = useLocation();
    const [status, setStatus] = useState('unsubmitted');
    const {data: user} = useUser();
    const usersRef = useFirestore()
        .collection('users');

    const onSubmit = async data => {
        setStatus('submitting');
        console.log('Saving with: ', data);
        try {
            await usersRef.doc(user.uid)
                .set({
                    uid: user.uid,
                    email: user.email,
                    displayName: data.name
                }, {merge: true});
            setStatus('success');
        } catch (error) {
            console.error(error);
            setStatus('error');
        }
    }

    if(status === 'success'){
        if(location?.state?.invitationCode){
            return <Redirect to={{pathname: `/pools/join/${location.state.invitationCode}`}}/>
        }

        return <Redirect to={{pathname: '/home'}}/>
    }

    return (
        <SignUpContainer>
            <h2>
                Awesome! We just need a bit more info to create your account
            </h2>
            <SignUpAndInForm onSubmit={handleSubmit(onSubmit)}>
                <InputGroup>
                    <SubmitButton type='submit'>
                        {status === 'submitting'
                            ? <i className="fas fa-spinner fa-spin"/>
                            : 'Submit'
                        }
                    </SubmitButton>
                </InputGroup>
            </SignUpAndInForm>
        </SignUpContainer>
    )
}
