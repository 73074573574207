import React from "react";
import styled, { css } from 'styled-components';
import { Redirect, useHistory } from 'react-router-dom';
import { useSigninCheck } from 'reactfire';
import { Waves } from './Waves';
import { theme } from './styles';
import race from './resources/fans.svg';
import business from './resources/business.svg';
import finish from './resources/finish.svg';

const VerticallySnappingContainer = styled.div`
  height: 100vh;
  scroll-snap-type: mandatory;
  scroll-snap-points-y: repeat(100vh);
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
  overflow-scrolling: touch; /* Needed to work on iOS Safari */
  overflow: auto;
  position: fixed;


  width: 100vw;
  top: 0;
  left: 0;
`

const scrollStopChildrenCss = css`
    height: 100vh;
    scroll-snap-align: start;
    scroll-snap-stop: always;
    position: relative;
  margin: auto;
  padding: 10vh 1em 0 1rem;
`

const LandingPageContainer = styled.div`
  ${scrollStopChildrenCss};
  max-width: 1000px;
  display: flex;
  flex-direction: row;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`

const TertiaryContainer = styled.div`
  ${scrollStopChildrenCss};
  background-color: ${props => props.theme.primaryDark};
  color: ${props => props.theme.primaryLight};
  display: grid;
  place-items: center center;
`;

const TertiaryContent = styled.div`
  max-width: 1000px;
  display: flex;
  flex-direction: row;
  margin: auto;

  @media (max-width: 600px) {
    flex-direction: column;
    height: calc(95vh - 4em);
  }
`

const CtaContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-content: end;
`

const CtaHeaderText = styled.h1`
  font-weight: 900;
  font-size: 5rem;

  @media (max-width: 900px) {
    font-size: 4rem;
  }

  @media (max-width: 600px) {
    line-height: 0;
    font-size: 3rem;
  }
`

const LandingPageImageContainer = styled.div`
  padding-left: 1rem;
  padding-top: 1rem;
  place-self: center center;
`

export const LargeImage = styled.img`
  width: 100%;
  height: 100%;
  max-height: 80vh;
  padding: 10px;
  object-fit: contain;
  //z-index: 10;
  position: relative;
`

// const WaveContinue = styled.div`
//   height: 80vh;
//   width: 100vw;
//   margin-top: -1rem;
//   display: grid;
//   place-items: center;
//   position: relative;
//   background-color: ${props => props.theme.tertiaryColor};
// `

export const SignUpButton = styled.button`
  border-radius: 0.3rem;
  color: ${props => props.theme.textColor};
  font-size: 1.2rem;
  padding: 0.8rem;
  border: 2px solid ${props => props.theme.textColor};
  font-weight: bold;
  background: none;
  max-width: 300px;
  z-index: 40;

  :hover {
    cursor: pointer;
    background: #FFFFFF10;
  }
`

const AlternativeSignUpButton = styled(SignUpButton)`
  color: ${props => props.theme.primaryLight};
  font-size: 1.2rem;
  padding: 0.8rem;
  border: 2px solid ${props => props.theme.primaryLight};
  font-weight: bold;
  background: none;
`

export const LandingPage = (props) => {
  const { signUpAction, invited } = props;
  const history = useHistory();

  const { data: { signedIn } } = useSigninCheck();

  async function loginWithGoogle() {
    if (signedIn) {
      history.push('/home');
    } else {
      history.push('/sign-up')
    }
  }

  if (signedIn) {
    return <Redirect to={{ pathname: '/home' }} />
  }

  return (
    <React.Fragment>
      <VerticallySnappingContainer>

        <LandingPageContainer>
          <CtaContainer>
            <CtaHeaderText>survivor.cool</CtaHeaderText>
            <h3>
              Cool Survivor Pools
            </h3>
            <h3>
              {invited
                ? 'You were invited to join a pool!'
                : ''
              }
            </h3>
            <SignUpButton onClick={signUpAction ?? loginWithGoogle}>
              {signedIn
                ? 'Go Home'
                : 'Sign Up'
              }
            </SignUpButton>
          </CtaContainer>
          <LandingPageImageContainer>
            <LargeImage src={race} />
          </LandingPageImageContainer>
          <Waves position={'fixed'} color={theme.tertiaryColor} />
        </LandingPageContainer>
        <TertiaryContainer>
          <TertiaryContent>
            <CtaContainer>
              <CtaHeaderText>survivor.cool</CtaHeaderText>
              <h3>
                Pick teams to win, lose, and beat the spread each week
              </h3>
              <AlternativeSignUpButton onClick={signUpAction ?? loginWithGoogle}>
                {signedIn
                  ? 'Go Home'
                  : 'Sign Up'
                }
              </AlternativeSignUpButton>
            </CtaContainer>
            <LandingPageImageContainer>
              <LargeImage src={business} />
            </LandingPageImageContainer>
          </TertiaryContent>
        </TertiaryContainer>
        <LandingPageContainer>
          <CtaContainer>
            <CtaHeaderText>survivor.cool</CtaHeaderText>
            <h3>
              Last one to not miss a pick wins
            </h3>
            <h3>
              {invited
                ? 'You were invited to join a pool!'
                : ''
              }
            </h3>
            <SignUpButton onClick={signUpAction ?? loginWithGoogle}>
              {signedIn
                ? 'Go Home'
                : 'Sign Up'
              }
            </SignUpButton>
          </CtaContainer>
          <LandingPageImageContainer>
            <LargeImage src={finish} />
          </LandingPageImageContainer>
        </LandingPageContainer>
      </VerticallySnappingContainer>
    </React.Fragment>
  )
}