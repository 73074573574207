import { ButtonLink, GlobalStyle, theme, TertiaryButton } from './styles';
import styled, { ThemeProvider } from 'styled-components';
import { BrowserRouter, Route, Switch, useHistory } from 'react-router-dom';
import { AuthCheck, SuspenseWithPerf, useAuth } from 'reactfire';
import { LandingPage } from './LandingPage';
import { AppContainer } from './AppContainer';
import { LoginPage } from './auth/LoginPage';
import { PrivateRoute } from './auth/PrivateRoute';
import { SplashScreen } from './SplashScreen';
import { CreateAccount } from './auth/CreateAccount';
import { HomePage } from './HomePage';
import { CreatePoolPage } from './CreatePool';
import { PoolHomePage } from './pools/PoolHomePage';
import { ManagePool } from './pools/ManagePool';
import { JoinPool } from './pools/JoinPool';
import { ErrorBoundary } from 'react-error-boundary';
import { DeletePool } from './pools/DeletePool';
import React from 'react';
import { PoolRulesPage } from './pools/PoolRules';
import { MakePicks } from './MakePicks';


const AuthenticatedRoute = (props) => {
    return (
        <Route {...props}>
            <AuthCheck fallback={<LoginPage signIn />}>
                {props.children}
            </AuthCheck>
        </Route>
    )
}

const ErrorContainer = styled.div`
  display: grid;
  place-items: center;
  height: 100vh;
`

const ErrorModal = styled.div`
  background-color: ${props => props.theme.primaryDark};
  color: ${props => props.theme.tertiaryColor};
  padding: 2rem;
  border-radius: 1rem;
`

const ErrorText = styled.div`
  background-color: ${props => props.theme.primaryLighter};
  color: ${props => props.theme.primaryDark};
  padding: 1rem;
  border-radius: 0.3rem;
`

const ErrorFallback = ({ error, resetErrorBoundary }) => {
    const history = useHistory();
    const auth = useAuth();
    
    const homeClicked = async () => {
        await auth.signOut();
        resetErrorBoundary();
        history.push('/');
    }

    console.error(error);

    return (
        <ErrorContainer role="alert">
            <ErrorModal>
                <h2>Something went wrong</h2>
                <ErrorText>
                    <pre>{error.name}</pre>
                    <pre>{error.message}</pre>
                </ErrorText>
                <p>
                    Sorry about that. We'll try and figure it out. You can try refreshing, or:
                </p>
                <TertiaryButton onClick={homeClicked}> Sign Out and Go Home </TertiaryButton>
            </ErrorModal>
        </ErrorContainer>
    )
}

function App() {
    return (
        <ThemeProvider theme={theme}>
            <GlobalStyle />
            <SuspenseWithPerf fallback={<SplashScreen />}>
                <BrowserRouter>
                    <AppContainer>
                        <ErrorBoundary FallbackComponent={ErrorFallback}>
                            <Switch>
                                <Route exact path='/'>
                                    <LandingPage />
                                </Route>
                                <Route exact path='/sign-in'>
                                    <LoginPage />
                                </Route>
                                <Route exact path='/sign-up'>
                                    <LoginPage signUp />
                                </Route>
                                <PrivateRoute exact path='/create-account'>
                                    <CreateAccount />
                                </PrivateRoute>
                                <PrivateRoute exact path='/home'>
                                    <HomePage />
                                </PrivateRoute>
                                <AuthenticatedRoute exact path='/create-pool'>
                                    <CreatePoolPage />
                                </AuthenticatedRoute>
                                <Route exact path='/pools/:id'>
                                    <PoolHomePage />
                                </Route>
                                <Route exact path='/pools/:id/rules'>
                                    <PoolRulesPage />
                                </Route>
                                <Route exact path='/pools/join/:invitationCode'>
                                    <JoinPool />
                                </Route>
                                <AuthenticatedRoute exact path='/pools/:id/picks'>
                                    <MakePicks />
                                </AuthenticatedRoute>
                                <AuthenticatedRoute exact path='/pools/:id/manage'>
                                    <ManagePool />
                                </AuthenticatedRoute>
                                <AuthenticatedRoute exact path='/pools/:id/delete'>
                                    <DeletePool />
                                </AuthenticatedRoute>
                            </Switch>
                        </ErrorBoundary>
                    </AppContainer>
                </BrowserRouter>
            </SuspenseWithPerf>
        </ThemeProvider>
    );
}

export default App;
