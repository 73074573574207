import { useState, useEffect } from 'react';
import { InlineLink, TertiaryButton } from '../styles';
import styled from 'styled-components';

const FakeLink = styled.span`
    text-decoration: underline;
    cursor: pointer;
`

export const CopyJoinCodeButton = (props) => {
    const { poolId, invitationCode, display } = props;
    const [copied, setCopied] = useState(false);
    useEffect(() => {
        if (copied) {
            setTimeout(() => setCopied(false), 500);
        }
    }, [copied])

    const copyJoinCode = async () => {
        await navigator.clipboard.writeText(`http://${window.location.host}/pools/join/${poolId}-${invitationCode}`)
        setCopied(true);
    }

    const Component = display === 'inline' ? FakeLink : TertiaryButton

    return (
        <Component onClick={copyJoinCode}>
            <i className="fas fa-copy" /> &nbsp;
            {copied ? 'Copied!' : 'Copy Invite Link'}
        </Component>
    )
}