import styled from 'styled-components';
import signUpImage from './resources/fans.svg';

export const LoadingImage = styled.img`
  height: 70vh;
  animation: sk-scaleout 3.0s infinite ease-in-out;
  max-width: 100vw;

  @keyframes sk-scaleout {
    0% {
      transform: scale(0.8);
    }
    50% {
      -webkit-transform: scale(1.0);
      transform: scale(1.0);
      opacity: 0.9;
    }
    100% {
      transform: scale(0.8);
    }
  }
`

const SplashScreenContainer = styled.div`
  display: grid;
  place-items: center;
  height: 100vh;
  max-width: 100vw;
`

const SplashScreenText = styled.h1`
    font-size: 4rem;
`

export const SplashScreen = ({text='Loading'}) => {
    return (
        <SplashScreenContainer>
            <SplashScreenText>{text}</SplashScreenText>
            <LoadingImage src={signUpImage}/>
        </SplashScreenContainer>
    )
}