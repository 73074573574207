import {
    PoolStandingsMetric,
    PoolStandingsMetricHeading, PoolStandingsName,
    PoolStandingsNameHeading, PoolStandingsRow,
    PoolStandingsTable,
    PoolStandingsTableHead,
    TableTitle
} from './PoolMembers';
import {useParams} from 'react-router-dom';
import {useFirestore, useFirestoreCollectionData, useFirestoreDocData, useUser} from 'reactfire';
import {getPicksWithResult} from './PoolWeekPicks';
import {useEffect, useState} from 'react';


const getResultsForWeek = (members, options, picks, week) => {
    const optionsByTeam = options?.reduce((acc, val) => ({...acc, [val.team]: val}), {}) ?? {};
    const picksGetter = getPicksWithResult(picks, optionsByTeam, week);
    const membersWithPicks = members.map(it => ({...it, ...picksGetter(it.uid), week}));
    const picksByMember = membersWithPicks.reduce((acc, val) => ({...acc, [val.uid]: {...val, week}}), {}) ?? {};

    return membersWithPicks;
}


const calculateStandings = (members, options, picks, activeWeek) => {
    const range = [...Array(activeWeek).keys()].map(it => it + 1);
    const perWeekResults = range.map(week => {
        const weekOptions = options[week];
        const weekPicks = picks[week];

        return getResultsForWeek(members, weekOptions, weekPicks, week);
    })

    const weeklyResultsByUser = perWeekResults.flatMap(it => it)
        // .filter(it => it.winResult !== undefined && it.loseResult !== undefined && it.atsResult !== undefined)
        .reduce((acc, val) => {
            return {
                ...acc,
                [val.uid]: {
                    ...(acc[val.uid] || {}),
                    [val.week]: val
                }
            }
        }, {})


    const uids = Object.keys(weeklyResultsByUser);

    const scores = Object.fromEntries(uids.map(it => [it, 0]))
    const win = new Set(uids);
    const lose = new Set(uids);
    const ats = new Set(uids);

    let oldWin = new Set(uids)
    let oldLose = new Set(uids)
    let oldAts = new Set(uids)

    let winWinner;
    let loseWinner;
    let atsWinner;

    for (let i = 2; i <= activeWeek; i++) {
        for (let uid of uids) {
            const member = weeklyResultsByUser[uid][i]

            if (member.winResult) {
                if (win.has(uid)) {
                    scores[uid] = scores[uid] + 1;
                }
            } else {
                win.delete(uid);
            }

            if (member.loseResult) {
                if (lose.has(uid)) {
                    scores[uid] += 1;
                }
            } else {
                lose.delete(uid);
            }
            if (member.atsResult) {
                if (ats.has(uid)) {
                    scores[uid] += 1;
                }
            } else {
                ats.delete(uid)
            }
        }

        if(win.size !== 0){
            oldWin = new Set([...win.values()])
        }

        if(lose.size !== 0){
            oldLose = new Set([...lose.values()])
        }

        if(ats.size !== 0){
            oldAts = new Set([...ats.values()])
        }
    }

    console.log({winWinner, loseWinner, atsWinner});

    const standings = uids.map(uid => ({
        uid,
        displayName: weeklyResultsByUser[uid][2].displayName,
        win: oldWin.has(uid),
        lose: oldLose.has(uid),
        ats: oldAts.has(uid),
        score: scores[uid]
    }));

    return standings.sort((a, b) => b.score - a.score)
}

const calculateStandings2 = (members, options, picks, activeWeek) => {
    const range = [...Array(activeWeek).keys()].map(it => it + 1);
    const perWeekResults = range.map(week => {
        const weekOptions = options[week];
        const weekPicks = picks[week];

        return getResultsForWeek(members, weekOptions, weekPicks, week);
    })


    const weeklyResultsByUser = perWeekResults.flatMap(it => it)
        // .filter(it => it.winResult !== undefined && it.loseResult !== undefined && it.atsResult !== undefined)
        .reduce((acc, val) => {
            return {
                ...acc,
                [val.uid]: {
                    ...(acc[val.uid] || {}),
                    [val.week]: val
                }
            }
        }, {})

    console.log(weeklyResultsByUser);

    const usersWithAggregateScores = Object.values(weeklyResultsByUser)
        .map(user => {
            const byWeekResults = Object.keys(user).map(it => ({
                week: it,
                win: user[it].winResult ?? false,
                lose: user[it].loseResult ?? false,
                ats: user[it].atsResult ?? false
            }));

            const getEarliestLoss = (results, type) => Math.min(...(byWeekResults.filter(it => !it[type])
                .map(it => parseInt(it.week))));

            const res = {
                uid: Object.values(user)[0].uid,
                displayName: Object.values(user)[0].displayName,
                win: getEarliestLoss(byWeekResults, 'win'),
                lose: getEarliestLoss(byWeekResults, 'lose'),
                ats: getEarliestLoss(byWeekResults, 'ats'),
            };

            return res;
        })
        .map(it => ({
            ...it,
            total: ['win', 'lose', 'ats'].map(key => getCountFromElimWeek(it[key], activeWeek)).reduce((a, b) => a + b, 0)
        }));

    usersWithAggregateScores.sort((a, b) => b.total - a.total);

    return usersWithAggregateScores;
}

const getCountFromElimWeek = (elimWeek, activeWeek) => elimWeek === Infinity ? activeWeek : elimWeek - 1

const getStandingMetricDisplay = (result) => {
    if (result) {
        return (
            <PoolStandingsMetric success={true}>
                <i className="fas fa-check-square"></i>
            </PoolStandingsMetric>
        )
    } else {
        return (
            <PoolStandingsMetric success={false}>
                <i className="fas fa-ban" title={`Eliminated`}/>
            </PoolStandingsMetric>
        )
    }
}

export const PoolStandings = (props) => {
    const {id} = useParams();

    const poolRef = useFirestore()
        .collection('pools')
        .doc(id);
    const {data: pool} = useFirestoreDocData(poolRef);
    const {data: user} = useUser();

    const poolMembersRef = poolRef.collection('members');
    const {data: poolMembers} = useFirestoreCollectionData(poolMembersRef);

    const protectedRef = useFirestore()
        .collection('pools')
        .doc(id)
        .collection('private')
        .doc('protected');

    const {data: protectedData} = useFirestoreDocData(protectedRef);

    const [standings, setStandings] = useState([]);

    useEffect(() => {
        if (pool.options && protectedData.picks) {
            setStandings(calculateStandings(poolMembers, pool.options, protectedData.picks, pool.activeWeek));
        }
    }, [pool, poolMembers, protectedData]);

    console.log(standings);

    return (
        <PoolStandingsTable>
            <PoolStandingsTableHead>
                <tr>
                    <TableTitle colSpan="5">
                        Standings
                    </TableTitle>
                </tr>
                <tr>
                    <PoolStandingsNameHeading> Name</PoolStandingsNameHeading>
                    <PoolStandingsMetricHeading> Win </PoolStandingsMetricHeading>
                    <PoolStandingsMetricHeading> Lose </PoolStandingsMetricHeading>
                    <PoolStandingsMetricHeading> ATS </PoolStandingsMetricHeading>
                    <PoolStandingsMetricHeading> Total </PoolStandingsMetricHeading>
                </tr>
            </PoolStandingsTableHead>
            <tbody>
            {standings.map((member, index) => (
                <PoolStandingsRow key={member.uid}>
                    <PoolStandingsName> {member.displayName} </PoolStandingsName>
                    {getStandingMetricDisplay(member.win)}
                    {getStandingMetricDisplay(member.lose)}
                    {getStandingMetricDisplay(member.ats)}
                    <PoolStandingsMetric>
                        {member.score}
                    </PoolStandingsMetric>
                </PoolStandingsRow>
            ))}
            </tbody>
        </PoolStandingsTable>
    )
}