import styled, {css} from 'styled-components';
import 'chartjs-adapter-moment';
import {act} from 'react-dom/test-utils';
import {PoolStandings} from './PoolStandings';
import {PoolWeekPicks} from './PoolWeekPicks';

/**
 * poolConfig has a value 'keyMetric' which determines standings
 * and a value 'goalDirection' which indicates which values are better
 * than others
 *
 * e.g. keyMetric: percentLost and goalDirection: positive
 *  means the list will be sorted by percentLost descending
 *
 *  keyMetric: currentWeight and goalDirection: negative
 *  would sort the list ascending by current weight
 */
export const sortMembers = (members, config) => {
    const metric = config.keyMetric
    const direction = config.goalDirection === 'positive' ? -1 : 1;
    return members.sort((a, b) => {
        return direction * getMostRecentMeasurement(a)?.[metric] + -1 * direction * getMostRecentMeasurement(b)?.[metric]
    })
}

export const PoolStandingsTable = styled.table`
  width: 100%;
  padding: 2rem;
  border-collapse: collapse;
  background-color: ${props => props.theme.primaryLighter};
  border-radius: 0.5rem;

  :not(:last-child) {
    margin-bottom: 1rem;
  }
`

export const PoolStandingsTableHead = styled.thead`
  font-weight: bold;
`

export const PoolStandingsRow = styled.tr`

`

export const PoolStandingsRankStyles = css`
  text-align: center;
`
export const PoolStandingsRank = styled.td`${PoolStandingsRankStyles}`
export const PoolStandingsRankHeading = styled.th`${PoolStandingsRankStyles}`

const PoolStandingsNameStyles = css`
  text-align: center;
`

const LightCol = styled.col`
  background-color: ${props => props.theme.primaryLighter};
`

const DarkCol = styled.col`
  background-color: ${props => props.theme.primaryLight};
    /* color: ${props => props.theme.primaryLighter}; */
`

const successStyles = css`
  color: ${props => props.theme.successColor};
`

const failStyles = css`
  color: ${props => props.theme.dangerColor};
`

export const PoolStandingsName = styled.td`
  ${PoolStandingsNameStyles}
  ${props => props.success === true ? successStyles : null};
  ${props => props.success === false ? failStyles : null};
`
export const PoolStandingsNameHeading = styled.th`${PoolStandingsNameStyles}`

export const poolStandingsMetricStyles = css`
  text-align: center;
`
export const PoolStandingsMetric = styled.td`
  ${poolStandingsMetricStyles};
  ${props => props.success === true ? successStyles : null};
  ${props => props.success === false ? failStyles : null};
`
export const PoolStandingsMetricHeading = styled.th`
  ${poolStandingsMetricStyles}
`

const getMostRecentMeasurement = (member) => {
    return Object.values(member.measurements || {}).sort((a, b) => b.timestamp.toMillis() - a.timestamp.toMillis())[0];
}

export const TableTitle = styled.td`
  font-weight: bold;
  font-size: 1.5rem;
  text-align: center;
  border-bottom: 0.15rem solid ${props => props.theme.primaryDark};
`

const PicksAndStandingsContainer = styled.div`
  margin-bottom: 1em;
`

export const PoolMembers = (props) => {
    const {members, picks, noStandings} = props;
    return (
        <PicksAndStandingsContainer>
            {!!picks ? <PoolWeekPicks {...props}/> : null}
            {noStandings ? null : <PoolStandings members={members}/>}
        </PicksAndStandingsContainer>
    )
}
