import styled from 'styled-components';
import {useParams} from 'react-router-dom';
import React, {useEffect} from 'react';
import {useFirestore, useFirestoreCollectionData, useFirestoreDocData, useUser} from 'reactfire';
import {Waves} from '../Waves';
import {InlineLink, theme, ButtonLink, TertiaryButtonLink} from '../styles';
import {PoolMembers} from './PoolMembers';
import {WeekSchedule, ScheduleFallback} from '../WeekSchedule';
import {CopyJoinCodeButton} from './CopyJoinCodeButton';
import {ErrorBoundary} from 'react-error-boundary';
import {PoolWeekPicks} from './PoolWeekPicks';
import {PoolStandings} from './PoolStandings';

export const PoolHomePageContainer = styled.div`
  display: grid;
  place-items: center top;

  min-height: 95vh;
`

export const PoolInfoContainer = styled.div`
  width: 80vw;
  margin: 0 auto;
  @media (max-width: 800px) {
    width: 95vw;
  }
`

export const PoolDurationContainer = styled.div`
  text-align: end;
`

const firestoreTimestampToDateString = (timestamp) => {
    return timestamp?.toDate().toLocaleDateString('en-US');
}

export const PoolDuration = (props) => {
    const {starts, ends} = props;
    return (
        <PoolDurationContainer>
            {firestoreTimestampToDateString(starts)} - {firestoreTimestampToDateString(ends)}
        </PoolDurationContainer>
    )
}

export const PoolTitleRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

export const PoolTitle = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const PoolDetailsContainer = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: minmax(600px, 1fr) 1fr;

  min-height: 40vh;

  @media (max-width: 900px) {
    grid-template-columns: 1fr;
  }
`


const userIsPoolAdmin = (user, poolMembers) => {
    if (user === null) {
        return false;
    } else {
        const userMember = poolMembers.find(it => it.uid === user.uid);
        return ['admin', 'owner'].includes(userMember.role);
    }
}

const TitleAndRulesLink = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const PoolTitleHeading = styled.h1`
  padding-right: 1rem;
`

const RulesAndPicksViewport = styled.div`
  display: flex;
  gap: 0.4rem;
  flex-direction: row;
  align-items: center;
`

const InTestContainer = styled.span`
  color: ${props => props.theme.tertiaryColor};
  background-color: ${props => props.theme.primaryDark};
  padding: 0.5rem;
  border-radius: 0.5rem;
  margin-bottom: 0.5rem;
`

export const PoolHomePage = (props) => {
    const {id} = useParams();

    const poolRef = useFirestore()
        .collection('pools')
        .doc(id);
    const {data: pool} = useFirestoreDocData(poolRef);
    const {data: user} = useUser();

    const lockAtDate = pool.picksLockAt.toDate();
    const locked = lockAtDate < Date.now();

    const poolMembersRef = poolRef.collection('members');
    const {data: poolMembers} = useFirestoreCollectionData(poolMembersRef);

    const getMemberPickStatus = async () => {
        const promises = poolMembers.map(it => poolRef
            .collection('members')
            .doc(it.uid)
            .collection('private')
            .doc('config')
            .get())

        const picks = (await Promise.all(promises)).map(it => it);

        console.log(picks);
    }

    const protectedRef = useFirestore()
        .collection('pools')
        .doc(id)
        .collection('private')
        .doc('protected');

    const {data: protectedData} = useFirestoreDocData(protectedRef);

    const submittedRef = useFirestore()
        .collection('pools')
        .doc(id)
        .collection('private')
        .doc('submitted');

    const {data: submittedData} = useFirestoreDocData(submittedRef);

    return (
        <React.Fragment>
            <PoolHomePageContainer>
                <PoolInfoContainer>
                    <PoolTitleRow>
                        <PoolTitle>
                            <TitleAndRulesLink>
                                <TitleContainer>
                                    <PoolTitleHeading> {pool.name}</PoolTitleHeading>
                                    {pool.testing
                                        ? <InTestContainer>Week 1 is a test week, picks made during this time will not
                                            count</InTestContainer>
                                        : null
                                    }
                                </TitleContainer>
                                <RulesAndPicksViewport>
                                    {protectedData?.invitationCode
                                        ? <small>
                                            <CopyJoinCodeButton
                                                display="inline"
                                                poolId={id}
                                                invitationCode={protectedData.invitationCode}
                                            /></small>
                                        : null
                                    }
                                    <small><InlineLink to={`/pools/${id}/rules`}> Rules </InlineLink></small>
                                    <TertiaryButtonLink
                                        to={`/pools/${id}/picks`}> {locked ? 'View' : 'Make'} Picks </TertiaryButtonLink>
                                </RulesAndPicksViewport>
                            </TitleAndRulesLink>
                            {userIsPoolAdmin(user, poolMembers)
                                ? <InlineLink to={`/pools/${id}/manage`}>Manage</InlineLink>
                                : null
                            }
                        </PoolTitle>
                    </PoolTitleRow>
                    <PoolDetailsContainer>
                        <div>
                            <PoolStandings/>
                            {[...Array(pool.activeWeek).keys()].reverse().slice(0, -1).map(it => it + 1).map(
                                week => <PoolWeekPicks options={pool.options[week]} members={poolMembers}
                                                       picks={protectedData?.picks?.[week]}
                                                       submitted={submittedData?.[week]}
                                                       activeWeek={week}/>
                            )}
                        </div>
                        <ErrorBoundary FallbackComponent={ScheduleFallback}>
                            <WeekSchedule activeWeek={pool.activeWeek}/>
                        </ErrorBoundary>
                    </PoolDetailsContainer>
                </PoolInfoContainer>
                <Waves color={theme.tertiaryColor} position='sticky'/>
            </PoolHomePageContainer>
        </React.Fragment>
    )
}