import styled from 'styled-components';
import {useQuery} from 'react-query';
import {ErrorBoundary} from 'react-error-boundary';


const GameInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  //height: 100%;
  background-color: ${props => props.theme.primaryLighter};
  padding: 0.5rem;


  @media (min-width: 1200px) or (max-width: 900px) {

    :nth-last-child(2) {
      border-radius: 0 0 0 0.5rem;
    }

    :nth-last-child(1) {
      border-radius: 0 0 0.5em 0;
    }
  }

  @media (max-width: 1200px) and (min-width: 900px) {
    :nth-child(1) {
      border-radius: 0.5em 0.5em 0 0;
    }

    :nth-last-child(1) {
      border-radius: 0 0 0.5em 0.5rem;
    }
  }
`

const ScoreOrLineTD = styled.td`
  text-align: end;
`

var formatter = new Intl.NumberFormat("en-GB", {style: "decimal", signDisplay: 'always'});

const GameInfo = (props) => {
    const {game} = props;

    const dateOptions = {
        weekday: 'short',
        hour: 'numeric',
        minute: 'numeric',
    }

    const started = game.status.type.state !== 'pre';
    let unstartedElements = []

    if (!started && game.competitions[0].odds) {
        const [favorite, spread] = game.competitions[0].odds[0].details.split(' ');
        const overUnder = game.competitions[0].odds[0].overUnder;

        unstartedElements = [
            formatter.format(spread),
            `O/U ${formatter.format(overUnder)}`
        ]

        if (favorite === game.competitions[0].competitors[1].team.abbreviation) {
            unstartedElements.reverse();
        }
    }

    const dateString = (new Date(game.date)).toLocaleString('en-US', dateOptions);
    return (
        <GameInfoContainer>
            <table>
                <tbody>
                <tr>
                    <td>{game.competitions[0].competitors[0].team.displayName}</td>
                    <ScoreOrLineTD>
                        {started
                            ? game.competitions[0].competitors[0].score
                            : <small> {unstartedElements[0]} </small>
                        }
                    </ScoreOrLineTD>
                </tr>
                <tr>
                    <td>{game.competitions[0].competitors[1].team.displayName}</td>
                    <ScoreOrLineTD>
                        {started
                            ? game.competitions[0].competitors[1].score
                            : <small> {unstartedElements[1]} </small>
                        }
                    </ScoreOrLineTD>
                </tr>
                <tr>
                    <td colSpan={2}><small> {game?.status?.type?.shortDetail ?? dateString} </small></td>
                </tr>
                </tbody>
            </table>
        </GameInfoContainer>
    )
}

const ScoreboardContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  place-items: center center;
  background-color: ${props => props.theme.primaryDark};
  grid-gap: 0.2rem;
  border-radius: 1rem;
  margin-bottom: 10vh;
  grid-auto-rows: max-content;
  height: min-content;
  @media (max-width: 1200px) and (min-width: 900px) {
    grid-template-columns: 1fr;
  }
`

const ScoreboardTitleContainer = styled.div`
  grid-column: 1 / -1;
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.primaryLighter};
  padding: 0.5rem;
  border-radius: 0.5em 0.5em 0 0;
  display: grid;
  place-items: center center;
  font-weight: bold;
  font-size: 1.5rem;
`


export const ScheduleFallback = ({error, resetErrorBoundary}) => {

    return (
        <ScoreboardContainer>
            <ScoreboardTitleContainer>
                Couldn't Fetch Scores
            </ScoreboardTitleContainer>
        </ScoreboardContainer>
    )
}

export const WeekSchedule = (props) => {
    const {activeWeek} = props;
    const {isLoading, error, data} = useQuery('scoreboard', async () => {
        const response = await fetch(`https://site.api.espn.com/apis/site/v2/sports/football/nfl/scoreboard?week=${activeWeek}`)
        if (!response.ok) {
            throw new Error('Network response was not ok')
        }
        return response.json();
    }, {refetchInterval: 120000})

    return (
        <ScoreboardContainer>
            <ScoreboardTitleContainer>
                NFL Scoreboard
            </ScoreboardTitleContainer>
            {data.events.map((it, index) => <GameInfo key={index} game={it}/>)}
        </ScoreboardContainer>
    )
}