import { Redirect } from 'react-router-dom';
import React, { useState } from "react";
import { useSigninCheck } from 'reactfire';
import { SignUpAndIn } from './SignUpAndIn';
import PropTypes from 'prop-types';
import { theme } from '../styles';
import { Waves } from '../Waves';

export function LoginPage({ signUp }) {
    // const { status, data: { signedIn } } = useSigninCheck();
    // const [loginSuccess] = useState(false);

    return (
        <div>
            <div>
                <SignUpAndIn signUp={signUp} />
            </div>
            <Waves position='fixed' color={theme.tertiaryColor} />
        </div>
    )
}

LoginPage.propTypes = {
    signUp: PropTypes.bool
}
