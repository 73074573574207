import {useUserWithProfile} from './hooks';
import styled from 'styled-components';
import {PoolSummary} from './pools/PoolSummary';
import React, {useEffect} from 'react';
import {ButtonLink, theme} from './styles';
import {Waves} from './Waves';
import signUpImage from './resources/fans.svg';
import {LargeImage} from './LandingPage';
import {useHistory} from 'react-router-dom';
import { useSigninCheck } from 'reactfire';


const HomePageGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`

const HomePageContainer = styled.div`
  margin: 1rem;
  background-size: cover;
  background-repeat: no-repeat;

  min-height: 100vh;

  ::after {
    content: "";
    background: url("./home_scene.svg");
    opacity: 0.3;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -20;
  }
`

const HomeDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const PoolLinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const NoPoolsContainer = styled.div`
  background-color: ${props => props.theme.primaryLighter};
  border-radius: 1rem;
  padding: 2rem;
  z-index: 40;
`

export const HomePage = (props) => {
  const {data: user} = useUserWithProfile();
  const history = useHistory();
  useEffect(() => {
    if(!user?.uid){
      history.push('/create-account');
    }
  }, [user, history])

  const poolsList = Object.keys(user?.pools || {});

    return (
      <React.Fragment>
            <HomePageContainer>
                <HomePageGrid>
                    <div>
                        <HomeDescriptionContainer>
                            <h1>Welcome {user?.displayName}</h1>
                        </HomeDescriptionContainer>
                        <PoolLinksContainer>
                            {poolsList.map(pool => <PoolSummary key={pool} poolId={pool}/>)}
                            {poolsList.length === 0
                                ? <NoPoolsContainer>
                                    <h3>
                                        If someone told you about survivor.cool, you should ask them for
                                        the link to join their group.
                                    </h3>
                                </NoPoolsContainer>
                                : null}
                        </PoolLinksContainer>
                    </div>
                    <LargeImage src={signUpImage}/>
                </HomePageGrid>
            </HomePageContainer>
            <Waves position='sticky' color={theme.tertiaryColor}/>
        </React.Fragment>
    )
}