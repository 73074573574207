import React, { useEffect, useState } from 'react';
import ReactJson from 'react-json-view';
import { useParams } from 'react-router-dom';
import { useFirestore, useFirestoreDocData, useFunctions } from 'reactfire';
import styled from 'styled-components';
import { ButtonLink, TertiaryButton, theme, InlineLink } from '../styles';
import { Waves } from '../Waves';
import { PoolHomePageContainer, PoolInfoContainer, PoolTitle } from './PoolHomePage';
import firebase from 'firebase/app';
import { CopyJoinCodeButton } from './CopyJoinCodeButton';

const ManagePoolContainer = styled(PoolHomePageContainer)`
    place-items: start center;
`

const ManagePoolItems = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  
  @media(max-width: 600px){
    flex-direction: column;
  }
`

const JsonContainer = styled.div`
    grid-column: 1/-1;
    display:grid;
    place-items: center center;
`;

const PoolActionsAndData = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
`

const FirebaseButtonContainer = styled.div`
    
`

const FirebaseActionButton = (props) => {
    const { action, title } = props;
    const [inProgress, setInProgress] = useState(false);
    const [message, setMessage] = useState('');

    const doAction = async () => {
        setInProgress(true);
        try {
            await action();
            setInProgress(false);
        } catch (error) {
            setMessage(error.message);
        }
    }
    return (
        <div>
            <TertiaryButton onClick={doAction}>
                {inProgress
                    ? <i className="fas fa-spinner fa-spin" />
                    : title
                }
            </TertiaryButton>
            {message
                ? <span> {message} </span>
                : null}
        </div>
    )
}

export const ManagePool = (props) => {
    const functions = useFunctions();
    const { id } = useParams();

    const poolRef = useFirestore()
        .collection('pools')
        .doc(id);

    const privatePoolRef = poolRef
        .collection('private')
        .doc('config');

    const protectedPoolRef = poolRef
        .collection('private')
        .doc('protected');

    const { data: publicPoolData } = useFirestoreDocData(poolRef, { idField: 'id' });
    const { data: privatePoolData } = useFirestoreDocData(privatePoolRef);
    const { data: protectedPoolData } = useFirestoreDocData(protectedPoolRef);

    const pool = {
        ...privatePoolData,
        ...publicPoolData,
    }

    const togglePoolShareableByMembers = async () => {
        if (protectedPoolData.invitationCode) {
            await protectedPoolRef.update({
                invitationCode: firebase.firestore.FieldValue.delete()
            });
        } else {
            await protectedPoolRef.set({
                invitationCode: privatePoolData.invitationCode
            }, { merge: true });
        }
    }

    const generateWeekOptions = async () => {
        try {
            await functions.httpsCallable('generateWeekOptions')({ poolId: id })
        } catch (error) {
            console.error(error);
        }
    }

    const manuallyPublishPicks = async () => {
        try {
            await functions.httpsCallable('manuallyPublishPicks')({ poolId: id })
        } catch (error) {
            console.error(error);
        }
    }

    const scoreWeek = async () => {
        try {
            await functions.httpsCallable('scoreWeek')({ poolId: id })
        } catch (error) {
            console.error(error);
        }
    }


    return (
        <React.Fragment>
            <ManagePoolContainer>
                <PoolInfoContainer>
                    <ManagePoolItems>
                        <PoolTitle>
                            <h1> Manage {pool.name}</h1>
                        </PoolTitle>
                    </ManagePoolItems>
                    <PoolActionsAndData>
                        <CopyJoinCodeButton poolId={id} invitationCode={pool.invitationCode} />
                        <ButtonLink to={`/pools/${id}/delete`}>
                            Delete this Pool
                        </ButtonLink>
                        <FirebaseActionButton
                            action={generateWeekOptions}
                            title={'Generate Week Options'}
                        />
                        <FirebaseActionButton
                            action={manuallyPublishPicks}
                            title={'Manually Publish Picks'}
                        />
                        <FirebaseActionButton
                            action={scoreWeek}
                            title={'Score Week'}
                        />
                        <TertiaryButton onClick={togglePoolShareableByMembers}>
                            {!!protectedPoolData.invitationCode
                                ? 'Prevent Sharing Invitation Link'
                                : 'Enable Sharing Invitation Link'}
                        </TertiaryButton>
                        <JsonContainer>
                            <ReactJson src={pool} />
                        </JsonContainer>
                    </PoolActionsAndData>
                </PoolInfoContainer>
                <Waves color={theme.tertiaryColor} position='fixed' />
            </ManagePoolContainer>
        </React.Fragment>
    )
}