import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { FirebaseAppProvider } from 'reactfire';
import firebase from 'firebase/app';
import LogRocket from 'logrocket';
import { QueryClient, QueryClientProvider } from 'react-query'

const firebaseConfig = {
    apiKey: process.env.REACT_APP_APIKEY,
    authDomain: process.env.REACT_APP_AUTHDOMAIN,
    databaseURL: process.env.REACT_APP_DATABASEURL,
    projectId: process.env.REACT_APP_PROJECTID,
    storageBucket: process.env.REACT_APP_STORAGEBUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
    appId: process.env.REACT_APP_APPID,
    measurementId: process.env.REACT_APP_MEASUREMENTID,
};

export const authProviderIds = process.env.REACT_APP_FIREBASE_PROJECT === 'prod'
    ? [firebase.auth.GoogleAuthProvider.PROVIDER_ID]
    : [firebase.auth.EmailAuthProvider.PROVIDER_ID]

if (window.location.hostname === 'localhost' && process.env.REACT_APP_FIREBASE_PROJECT !== 'prod') {
    // console.log("testing locally -- hitting local functions and firestore emulators");
    // firebase.functions().useEmulator('localhost', 5001);
    // firebase.firestore().settings({
    //     host: 'localhost:8080',
    //     ssl: false
    // });
} else {
    // only log sessions in Prod
    if (window.location.hostname !== 'localhost') {
        LogRocket.init('0umj27/losing-rocks');
    }
}

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            suspense: true,
        },
    },
})

ReactDOM.render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <FirebaseAppProvider firebaseConfig={firebaseConfig} suspense>
                <App />
            </FirebaseAppProvider>
        </QueryClientProvider>
    </React.StrictMode>,
    document.getElementById('root')
);