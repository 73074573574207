import { useEffect, useState } from 'react';
import { throttle } from 'lodash';
import { useFirestore, useFirestoreDocData, useUser, useSigninCheck } from 'reactfire';

export const useBreakpoint = () => {
    const [brkPnt, setBrkPnt] = useState(() => window.innerWidth);

    useEffect(() => {
        const calcInnerWidth = throttle(function () {
            setBrkPnt(window.innerWidth)
        }, 200);
        window.addEventListener('resize', calcInnerWidth);
        return () => window.removeEventListener('resize', calcInnerWidth);
    }, []);

    return brkPnt;
}

export const useUserWithProfile = () => {
    const res = useSigninCheck();
    const {status, data: {signedIn, user}} = res;
    const userRef = useFirestore()
        .collection('users')
        .doc(user.uid)

    return useFirestoreDocData(userRef);
}


export const useManagedOrLoggedInUser = (managedUserId) => {
    const { data: loggedInUser } = useUser();

    const userRef = useFirestore()
        .collection('users')
        .doc(managedUserId || loggedInUser.uid);

    const { data: user } = useFirestoreDocData(userRef);

    return user;
}