import {css} from 'styled-components';

export const reactDatepickerStyles = css`
  .DateInput_input, .DateInput, .SingleDatePickerInput, .DateRangePickerInput, .DateRangePickerInput_arrow {
    background-color: ${props => props.theme.primaryLight};
    color: ${props => props.theme.primaryDark};
    border-radius: 0.3rem;
  }

  .DateInput_input::placeholder {
    color: ${props => props.theme.primaryDark};
  }
  
  .DateRangePicker_withBorder, .SingleDatePicker_withBorder {
    border: none;
  }

  .DateRangePickerInput_arrow_svg {
    fill: ${props => props.theme.primaryDark};
  }

  .DateRangePickerInput__withBorder, .SingleDatePickerInput__withBorder {
    border: none;
  }

  .DateRangePickerInput, .SingleDatePickerInput, .SingleDatePicker, .DateRangePicker, .DateInput, .DateInput_input {
    border-right: 0.3rem;
    z-index: 20;
  }
`