import { useFunctions, useSigninCheck, useFirestore, useFirestoreDoc } from 'reactfire';
import { SplashScreen } from '../SplashScreen';
import { SignUpAndIn } from '../auth/SignUpAndIn';
import { Redirect, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { ErrorText } from '../input-components';
import styled from 'styled-components';
import { Waves } from '../Waves';
import { theme } from '../styles';
import { LandingPage } from '../LandingPage';
import { PoolRulesPage } from './PoolRules';

const JoinPoolErrorContainer = styled.div`
  display: grid;
  place-items: center;
  height: 80vh;
`

const ErrorModal = styled.div`
  width: 90vw;
  max-width: 600px;
  padding: 2rem;
  text-align: center;
  border-radius: 0.3rem;
  font-size: 1.5rem;
  background-color: ${props => props.theme.primaryDark};
`

/**
 *  if a user is logged in, add them to the pool
 *  if a user is logged out, prompt to sign in and attach a
 */
export const JoinPool = ({ props }) => {
    const { status, data: { signedIn, user } } = useSigninCheck();
    const { invitationCode } = useParams();
    const [joinStatus, setJoinStatus] = useState(null);
    const [confirmJoinPool, setConfirmJoinPool] = useState(false);
    const [agreedToRules, setAgreedToRules] = useState(false);
    const joinPool = useFunctions().httpsCallable('joinPool');
    const [retryCount, setRetryCount] = useState(0);
    console.log({signedIn, invitationCode, joinPool, joinStatus, retryCount, agreedToRules});
    useEffect(() => {
        if (signedIn && invitationCode && retryCount < 5 && joinStatus?.status !== 'submitting' && agreedToRules) {
            setJoinStatus({status: 'submitting', message: 'Joining the Pool'});
            setRetryCount(retryCount + 1);
            joinPool({ invitationCode })
                .then(res => setJoinStatus({ status: 'success' }))
                .catch(err => setJoinStatus({ status: 'error', message: err.message }));
        }
    }, [signedIn, invitationCode, joinPool, joinStatus, retryCount, agreedToRules])

    if (joinStatus) {
        if (joinStatus.status === 'success') {
            console.log('joined, going home');
            return <Redirect to={{ pathname: '/home' }} />
        } else if(joinStatus.status === 'error'){
            return (
                <React.Fragment>
                    <JoinPoolErrorContainer>
                        <ErrorModal>
                            <ErrorText> {joinStatus.message}</ErrorText>
                        </ErrorModal>
                    </JoinPoolErrorContainer>
                    <Waves color={theme.tertiaryColor} position='fixed' />
                </React.Fragment>
            )
        }
    }
    if (status === 'loading') {
        return <SplashScreen />
    } else if (!signedIn && !confirmJoinPool) {
        return <LandingPage invited signUpAction={() => setConfirmJoinPool(true)} />
    } else if (!signedIn) {
        return <SignUpAndIn doNotNavigate signUp invitationCode={invitationCode}/>
    } else if (!agreedToRules){
        return <PoolRulesPage confirm onConfirm={() => setAgreedToRules(true)} poolId={invitationCode.split('-')[0]}/>
    } else {
        return <SplashScreen text='Joining the Pool!' />
    }
}