import React, {useEffect, useState} from "react";
import {Link, useHistory, useLocation} from 'react-router-dom';
import styled, {css} from 'styled-components';
import {useBreakpoint} from "./hooks";
import { useFirebaseApp, useSigninCheck, useAuth } from 'reactfire';

const AppHeader = styled.div`
  position: sticky;
  top: 0;
  width: 100vw;
  z-index: 20;
  padding: 0.5rem;
  min-height: 5vh;
  display: flex;
  flex-direction: ${props => props.condensed ? 'column' : 'row'};
  justify-content: space-between;
  flex-direction: ${props => props.condensed ? 'flex-end' : 'center'};
  border-bottom: 2px solid ${props => props.theme.textColor};
  background-color: ${props => props.theme.primaryLight};

  @media (max-width: 600px) {
    padding: 0.25rem;
  }
`

const condensedCss = css`
  display: flex;
  flex-direction: column;
`

const ExpandedMenu = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-content: end;
  text-align: right;
`

const CondensedHeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  width: 100%;
`

const LoggedInMenu = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  ${props => props.condensed ? condensedCss : ''};
`
const headerLinkStyles = css`
  margin: 0.4rem;
  padding-bottom: 0.1rem;

  :hover {
    border-bottom: 0.1em ${props => props.theme.textColor} solid;
  }
`
const AppHeaderLink = styled(Link)`
  ${headerLinkStyles};
`

const AppHeaderAnchor = styled.a`
  ${headerLinkStyles};
`

const AppBody = styled.div`
  
  background-color: ${props => props.theme.primaryLight};

  @media (max-width: 450px) {
    padding: 0;
  }
  
  position: relative;
  z-index: 1;
`

const ExpandMenu = styled.div`
  padding: 0.4rem;
  font-size: 1.4rem;
`

const AppTitle = styled.span`
  font-size: 1.17rem;
`

export const AppContainer = (props) => {
    const history = useHistory();
    const width = useBreakpoint();
    const {pathname} = useLocation();
    const [menuOpened, setMenuOpened] = useState(false);
    const condensed = width < 550;

    const {data: {signedIn}} = useSigninCheck();
    const firebase = useFirebaseApp();
    const auth = useAuth();

    const logOut = async () => {
        await auth.signOut();
        window.location.reload();
        history.push('/')
    }

    useEffect(() => {
        setMenuOpened(false);
    }, [pathname])

    const menuItems = <React.Fragment>
        {!signedIn
            ? <AppHeaderLink to={'/sign-in'}> Log In </AppHeaderLink>
            : <React.Fragment>
                <AppHeaderLink to="/home">
                    Home
                </AppHeaderLink>
                <AppHeaderAnchor onClick={logOut}> Sign Out </AppHeaderAnchor>
            </React.Fragment>
        }
    </React.Fragment>


    if (condensed) {
        return (<React.Fragment>
            <AppHeader condensed>
                <CondensedHeaderRow>
                    <AppTitle>
                        <Link to={signedIn ? '/' : '/home'}>
                            survivor.cool
                        </Link>
                    </AppTitle>
                    <ExpandMenu onClick={() => setMenuOpened(!menuOpened)}> <i className="fas fa-bars"/></ExpandMenu>
                </CondensedHeaderRow>
                {menuOpened && <ExpandedMenu>
                    {menuItems}
                </ExpandedMenu>}
            </AppHeader>
            <AppBody>
                {props.children}
            </AppBody>
        </React.Fragment>)
    } else {

        return (<React.Fragment>
            <AppHeader>
                <h3>
                    <Link to={signedIn ? '/' : '/home'}>
                        survivor.cool
                    </Link>
                </h3>
                <LoggedInMenu>
                    {menuItems}
                </LoggedInMenu>
            </AppHeader>
            <AppBody>
                {props.children}
            </AppBody>
        </React.Fragment>)
    }
}
