import React from 'react';
import { useParams } from 'react-router-dom';
import { useFirestore, useFirestoreDocData } from 'reactfire';
import styled from 'styled-components';
import { BasicInfoPage } from '../BasicInfoPage';
import { TertiaryButton, InlineLink } from '../styles';

const ReadableColumn = styled.div`
  max-width: 700px;
  width: 100%;
`

const AcceptRules = styled.div`
    display: grid;
    place-items: center center;
`

const JoinPoolButton = styled(TertiaryButton)`
    font-size: 1.5rem;
`

const VenmoLink = styled.a`
    text-decoration: underline;
    padding: 0.3rem;
    border-radius: 0.3rem;
    background-color: ${props => props.theme.primaryDark};
    color: ${props => props.theme.tertiaryColor};

    :hover {
        background-color: ${props => `${props.theme.primaryDark}bb`};
    }
`

export const PoolRulesPage = (props) => {
    const { id } = useParams();
    const { confirm, onConfirm, poolId } = props;
    const poolRef = useFirestore()
        .collection('pools')
        .doc(id ?? poolId);
    const { data: pool } = useFirestoreDocData(poolRef);

    return (
        <BasicInfoPage>
            <ReadableColumn>
                <h1> {pool.name} Rules </h1>
                <h3> This is a survivor pool, in each category you may only pick a team <b> once </b> for the entire year</h3>
                <p> Entry is <b>${pool.buyIn}</b> to be paid by Oct 1
                    {pool.venmo
                        ? <span> via Venmo <VenmoLink noopener nofollow target='_blank' href={`https://venmo.com/${pool.venmo}`}> @{pool.venmo} </VenmoLink> </span>
                        : null
                    }
                </p>
                <p> Each week, you must select an NFL team to
                    <ul>
                        <li>win their matchup</li>
                        <li>lose their matchup </li>
                        <li>win their matchup against the spread (ATS)</li>
                    </ul>
                </p>
                <p>
                    Additionally:
                    <ul>
                        <li> If you fail to make a pick in a category in a week, that is a loss for that week.</li>

                        <li> If your pick in a given category is correct you 'survive' that week in that category.</li>

                        <li> Once you get a week wrong in a given category, you're done picking that category for the year.</li>

                        <li> If your pick ATS is a push (e.g. wins by 3 when spread is -3) it is a loss.</li>

                        <li> We will award a winner in each category and an overall winner who lasted the longest across all three, each
                            winning 25% of the pool.</li>
                    </ul>
                </p>

                {confirm
                    ? <AcceptRules>
                        <p> <strong> If you agree to these rules </strong> </p>
                        <JoinPoolButton onClick={onConfirm}> Join Pool </JoinPoolButton>
                    </AcceptRules>
                    : null
                }
            </ReadableColumn>
        </BasicInfoPage>
    )
}
