import styled from 'styled-components'
import poolImage from './resources/poolImage.png';
import { LargeImage } from './LandingPage';
import { ErrorText, InputGroup, InputLabel, SignInInput, SignUpAndInForm, SubmitButton, SignInWithIconSpan } from './input-components';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Waves } from './Waves';
import { ButtonLink, theme } from './styles';
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { useFirestore } from 'reactfire';
import { useBreakpoint, useUserWithProfile } from './hooks';
import { useQuery } from 'react-query';
import { first } from 'lodash';
import firebase from 'firebase/app';

const CreatePoolPageContainer = styled.div`
  display: grid;
  place-items: center;
`

const FormAndImageContainer = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;

  z-index: 10;
  position: relative;
`

const getActiveWeekAndScheduleTime = async () => {
    const data = await fetch('https://site.api.espn.com/apis/site/v2/sports/football/nfl/scoreboard').then(res =>
        res.json()
    )

    const activeWeek = data.week.number;
    const firstEvent = data.events.filter(it => it.status.type.state === 'pre')[0]
    const picksLockAt = firstEvent.date;

    return { activeWeek, picksLockAt }
}

export const CreatePoolPage = (props) => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [status, setStatus] = useState('unsubmitted');
    const size = useBreakpoint();
    console.log(size);
    const { data: user } = useUserWithProfile();
    const newPoolRef = useFirestore()
        .collection('pools')
        .doc();

    const userRef = useFirestore()
        .collection('users')
        .doc(user.uid);

    const firestore = useFirestore();

    const onSubmit = async (data) => {
        setStatus('submitting');
        console.log('Saving with: ', data);
        console.log('User', user);

        const { activeWeek, picksLockAt } = await getActiveWeekAndScheduleTime();

        try {
            await firestore.runTransaction(async (transaction) => {

                const poolItem = {
                    name: data.poolName,
                    owner: user.uid,
                    buyIn: data.buyIn,
                    venmo: data.venmo,
                    visibility: 'public',
                    activeWeek,
                    picksLockAt: firebase.firestore.Timestamp.fromDate(new Date(picksLockAt))
                }

                transaction.set(newPoolRef, poolItem);
                const path = `pools.${newPoolRef.id}`

                transaction.update(userRef, {
                    [path]: poolItem
                });

                transaction.set(newPoolRef.collection('members').doc(user.uid), {
                    displayName: user.displayName,
                    email: user.email,
                    uid: user.uid,
                    role: 'owner',
                });
            })
            setStatus('success');
        } catch (error) {
            console.error(error);
            setStatus('error');
        }
    }

    return (
        <CreatePoolPageContainer>
            <FormAndImageContainer>
                <FormContainer>
                    <h2> Create a Pool </h2>
                    <SignUpAndInForm onSubmit={handleSubmit(onSubmit)}>
                        <InputGroup>
                            <InputLabel>
                                pool name
                            </InputLabel>
                            <SignInInput
                                {...register("poolName", { required: true })} />
                            {errors.poolName
                                ? <ErrorText> Beuller? </ErrorText>
                                : null
                            }
                        </InputGroup>
                        <InputGroup>
                            <InputLabel>
                                buy In
                            </InputLabel>
                            <SignInWithIconSpan>
                                <i className="fas fa-dollar-sign"></i>
                                <SignInInput
                                    type="number"
                                    min="0"
                                    step="any"
                                    {...register("buyIn", { required: true, defaultValue: 0 })} />
                            </SignInWithIconSpan>
                            {errors.buyIn
                                ? <ErrorText> It can be zero, that's cool too </ErrorText>
                                : null
                            }
                        </InputGroup>
                        <InputGroup>
                            <InputLabel>
                                venmo account for buy ins (optional)
                            </InputLabel>
                            <SignInWithIconSpan>
                                <i className="fas fa-at"></i>
                                <SignInInput
                                    {...register("venmo", { required: false })} />
                            </SignInWithIconSpan>
                            {errors.venmo
                                ? <ErrorText> Beuller? </ErrorText>
                                : null
                            }
                        </InputGroup>
                        <InputGroup>
                            {status !== 'success'
                                ? <SubmitButton type="submit">
                                    {status === 'submitting'
                                        ? <i className="fas fa-spinner fa-spin" />
                                        : 'Create Your Pool'
                                    }
                                </SubmitButton>
                                : <InputLabel>
                                    See, that wasn't bad! Check out your shiny new pool
                                    <ButtonLink to={'/home'}> here </ButtonLink>
                                </InputLabel>
                            }
                        </InputGroup>
                    </SignUpAndInForm>
                </FormContainer>
                <LargeImage src={poolImage} />
            </FormAndImageContainer>
            <Waves color={theme.tertiaryColor} position={'sticky'} />
        </CreatePoolPageContainer>
    )
}