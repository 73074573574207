import {
    PoolStandingsMetricHeading, PoolStandingsName,
    PoolStandingsNameHeading,
    PoolStandingsRow, PoolStandingsTable,
    PoolStandingsTableHead,
    TableTitle
} from './PoolMembers';
import React from 'react';

const formatter = new Intl.NumberFormat("en-GB", {style: "decimal", signDisplay: 'always'});
const notStarted = (it) => {
    if (!it) {
        return false;
    }
    const {score, diff} = it;
    return parseInt(score) === 0 && parseInt(diff) === 0;
}

export const getPicksWithResult = (picks, options, activeWeek) => (memberId) => {
    const memberPicks = {
        ...picks?.[memberId],
    }

    return {
        ...memberPicks,
        winResult: notStarted(options[memberPicks.win]) ? null : options[memberPicks.win]?.won,
        loseResult: notStarted(options[memberPicks.lose]) ? null : options[memberPicks.lose]?.won === true
            ? false
            : options[memberPicks.lose]?.won === false
                ? true
                : null,
        atsResult: notStarted(options[memberPicks.ats]) ? null : options[memberPicks.ats]?.wonAts,
    }
}

export const PoolWeekPicks = (props) => {
    const {members, picks, activeWeek, options, submitted} = props;
    const optionsByTeam = options?.reduce((acc, val) => ({...acc, [val.team]: val}), {}) ?? {};
    const picksGetter = getPicksWithResult(picks, optionsByTeam, activeWeek);
    const membersWithPicks = members.map(it => ({...it, ...picksGetter(it.uid)}));

    return (
        <PoolStandingsTable>
            <PoolStandingsTableHead>
                <tr>
                    <TableTitle colSpan="4">
                        Week {activeWeek} Picks
                    </TableTitle>
                </tr>
                <tr>
                    <PoolStandingsNameHeading> Name</PoolStandingsNameHeading>
                    <PoolStandingsMetricHeading> Win </PoolStandingsMetricHeading>
                    <PoolStandingsMetricHeading> Lose </PoolStandingsMetricHeading>
                    <PoolStandingsMetricHeading> Win ATS </PoolStandingsMetricHeading>
                </tr>
            </PoolStandingsTableHead>
            <tbody>
            {membersWithPicks.map((member, index) => (
                <PoolStandingsRow key={member.uid}>
                    <PoolStandingsName> {member.displayName} </PoolStandingsName>
                    <PoolStandingsName success={member.winResult}>
                        {member.win?.split(' ')[0]} {!member.win && submitted?.[member.uid]?.includes('win') ? <i className="fas fa-check-circle" aria-hidden="true"/> : null}
                    </PoolStandingsName>
                    <PoolStandingsName success={member.loseResult}>
                        {member.lose?.split(' ')[0]} {!member.lose && submitted?.[member.uid]?.includes('lose') ? <i className="fas fa-check-circle" aria-hidden="true"/>: null}
                    </PoolStandingsName>
                    <PoolStandingsName success={member.atsResult}>
                        {member.ats?.split(' ')[0]} {!member.ats && submitted?.[member.uid]?.includes('ats') ? <i className="fas fa-check-circle" aria-hidden="true"/> : null}
                        <small> {member.ats && formatter.format(optionsByTeam[member.ats]?.odds)} </small>
                    </PoolStandingsName>
                </PoolStandingsRow>
            ))}
            </tbody>
        </PoolStandingsTable>
    )
}