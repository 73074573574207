import styled, { css } from 'styled-components';
import { PrimaryButton } from './styles';

export const signInTextCss = css`
  font-size: 1.3rem;

  @media (max-width: 500px) {
    font-size: 1rem;
  }
`

export const SignInInput = styled.input`
  border-radius: 0.3rem;
  border: none;
  padding: 0.5em 0.2rem;
  background-color: ${props => props.theme.primaryLight};
  color: ${props => props.theme.primaryDark};
  ${signInTextCss};
`

export const SignInWithIconSpan = styled.span`
  position: relative;

> i {
  position: absolute;
  display: block;
  transform: translate(0, -50%);
  top: 50%;
  pointer-events: none;
  width: 25px;
  text-align: center;
	font-style: normal;
}

> input {
  width: 100%;
  padding-left: 25px;
	padding-right: 0;
}
`

export const SignInInputWithIcon = (props) => {
  const { icon, ...inputProps } = props
  return (
    <SignInWithIconSpan>
      {icon}
      <SignInInput {...inputProps}/>
    </SignInWithIconSpan>
  )
}

export const InputLabel = styled.label`
  margin: 0.5em 0;
  color: ${props => props.theme.primaryLight};
  ${signInTextCss};
  display: inline-block;
`

export const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0.5em 1rem;
`

export const ErrorText = styled.span`
  padding-top: 0.5rem;
  color: ${props => props.theme.tertiaryColor};
  ${signInTextCss};
`

export const SubmitButton = styled(PrimaryButton)`
  min-width: 0;
  margin: 1em 0;
  ${signInTextCss};
`

export const SignUpContainer = styled.div`
  display: grid;
  place-items: center;
`


export const SignUpAndInForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 70vw;
  max-width: 700px;

  @media (max-width: 500px) {
    width: 95vw;
  }

  background-color: ${props => props.theme.primaryDark};
  border-radius: 1rem;
  margin-bottom: 10vh;
`