import { useForm } from "react-hook-form";
import styled from 'styled-components';
import { InlineLink } from '../styles';
import { useAuth, useFirestore } from 'reactfire';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import {
    ErrorText,
    InputGroup,
    InputLabel,
    SignInInput,
    SignUpAndInForm,
    SignUpContainer,
    SubmitButton
} from '../input-components';
import firebase from 'firebase/app';
import 'firebase/auth';

const useSignInWithEmailAndPassword = () => {
    const auth = useAuth();

    return async (email, password, newAcct = false) => {
        if (newAcct) {
            return auth.createUserWithEmailAndPassword(email, password)
        } else {
            return auth.signInWithEmailAndPassword(email, password)
        }
    }
}

const useSignInWithGoogle = () => {
    const auth = useAuth();
    return async () => auth.signInWithPopup(new firebase.auth.GoogleAuthProvider())
}

const JankyEmailDescription = styled.div`
  color: ${props => props.theme.primaryLighter};
`

const SignUpLink = styled(InlineLink)`
  color: ${props => props.theme.primaryLighter};
`

const SignInButtons = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`

export const SignUpAndIn = (props) => {
    const { signUp: initialSignUp, doNotNavigate } = props;
    const [signUp, setSignUp] = useState(!!initialSignUp);
    const history = useHistory();
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [status, setStatus] = useState('unsubmitted');
    const usersRef = useFirestore().collection('users');
    const signInWithEmailAndPassword = useSignInWithEmailAndPassword();
    const signInWithGoogle = useSignInWithGoogle();

    useEffect(() => {
        setStatus('unsubmitted');
        setSignUp(!!initialSignUp);
    }, [initialSignUp])

    const onSubmit = async data => {
        setStatus('submitting');
        try {
            const { user } = await signInWithEmailAndPassword(data.email, data.password, signUp);
            if (signUp) {
                await usersRef.doc(user.uid)
                    .set({
                        uid: user.uid,
                        email: user.email,
                        displayName: data.name
                    }, { merge: true });
            }
            setStatus('submitted');
            if(!doNotNavigate){
                history.push('/');
            }
        } catch (error) {
            switch (error.code) {
                case 'auth/user-not-found':
                    setStatus('not-found');
                    break;
                case 'auth/email-already-in-use':
                    setStatus('email-used');
                    break;
                default:
                    console.error(error);
            }
        }
    }

    const onSignInWithGoogle = async data => {
        setStatus('signing-with-google');
        try {
            const { user } = await signInWithGoogle()
            if (signUp) {
                const res = await usersRef.doc(user.uid)
                    .set({
                        uid: user.uid,
                        email: user.email,
                        displayName: user.displayName
                    }, { merge: true });
                console.log('finishing google');
                console.log(res);
            }
            setStatus('submitted');
            if(!doNotNavigate){
                history.push('/');
            }
        } catch (error) {
            console.error(error);
            if (error.code === 'auth/user-not-found') {
                setStatus('not-found')
            }
        }
    }

    console.log(status)

    return (
        /* "handleSubmit" will validate your inputs before invoking "onSubmit" */
        <SignUpContainer>
            {signUp
                ? <h1> Sign Up </h1>
                : <h1> Sign In</h1>
            }
            <SignUpAndInForm onSubmit={handleSubmit(onSubmit)}>
                <InputGroup>
                    <SubmitButton onClick={onSignInWithGoogle} type="button">
                        <i className="fa fa-google" aria-hidden="true" /> Sign In with Google
                    </SubmitButton>
                    <InputLabel>
                        <p> Or you can sign up with an email and password </p>
                    </InputLabel>
                </InputGroup>
                {signUp
                    ? <InputGroup>
                        <InputLabel> name </InputLabel>
                        <SignInInput {...register("name", { required: true })} />
                        {errors.name && <ErrorText>We need a name for you, try 'Billbert' or something</ErrorText>}
                    </InputGroup>
                    : null}
                <InputGroup>
                </InputGroup>
                <InputGroup>
                    <InputLabel>
                        email
                    </InputLabel>
                    <SignInInput autocomplete="email" inputmode='email'
                        type='email' {...register("email", { required: true })} />
                    {errors.email &&
                        <ErrorText>An email is required, you can sign in with google if you prefer</ErrorText>}
                </InputGroup>

                <InputGroup>
                    <InputLabel>
                        password
                    </InputLabel>
                    <SignInInput autocomplete="password" inputmode='password'
                        type='password' {...register("password", { required: true })} />
                    {errors.email &&
                        <ErrorText>Please enter a password, this is going to google so they probably know it
                            anyway</ErrorText>}
                </InputGroup>

                <InputGroup>
                    {status === 'submitted'
                        ? <JankyEmailDescription>
                            <p>
                                logged in!
                            </p>
                        </JankyEmailDescription>
                        : status === 'not-found'
                            ? <JankyEmailDescription>
                                <p>
                                    No one was found in our system with this email, maybe you should
                                    <br />
                                    <SignUpLink to={'/sign-up'}>Sign up </SignUpLink>
                                </p>
                            </JankyEmailDescription>
                            : status === 'email-used'
                                ? <JankyEmailDescription>
                                    <p>
                                        This email is already registered, maybe you want to <SignUpLink to={'/sign-in'}>sign
                                            in </SignUpLink> instead
                                    </p>
                                </JankyEmailDescription>
                                : <SignInButtons>
                                    {status === 'signing-with-google'
                                        ? null
                                        : <SubmitButton type="submit">
                                            {status === 'submitting'
                                                ? <i className="fas fa-spinner fa-spin" />
                                                : signUp
                                                    ? 'Submit'
                                                    : 'Sign In'
                                            }
                                        </SubmitButton>
                                    }
                                </SignInButtons>
                    }
                </InputGroup>
            </SignUpAndInForm>
        </SignUpContainer >
    );
}


SignUpAndIn.propTypes = {
    signUp: PropTypes.bool,
}