import styled, {css} from 'styled-components';
import React, {useState, useEffect} from 'react';
import {TertiaryButton} from './styles';
import {useParams} from 'react-router-dom';
import {useFirestore, useFirestoreDocData, useFunctions, useUser} from 'reactfire';
import {useQuery} from 'react-query';

const formatter = new Intl.NumberFormat("en-GB", {style: "decimal", signDisplay: 'always'});

const teams = [
    "ARI Cardinals",
    "ATL Falcons",
    "BAL Ravens",
    "BUF Bills",
    "CAR Panthers",
    "CHI Bears",
    "CIN Bengals",
    "CLE Browns",
    "DEN Broncos",
    "DET Lions",
    "GB Packers",
    "HOU Texans",
    "IND Colts",
    "JAX Jaguars",
    "KC Chiefs",
    "LA Chargers",
    "LA Rams",
    "LV Raiders",
    "MIA Dolphins",
    "MIN Vikings",
    "NE Patriots",
    "NO Saints",
    "NY Giants",
    "NY Jets",
    "PHI Eagles",
    "PIT Steelers",
    "SEA Seahawks",
    "SF 49ers",
    "TEN Titans",
    "WAS Football Team",
];

const PicksViewport = styled.div`
  display: grid;
  width: 95vw;
  margin: auto;
  place-items: center top;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1rem;
  @media (max-width: 500px) {
    grid-template-columns: 1fr;
  }
`

const TeamsListPickerViewport = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.primaryLighter};
  /* padding: 1rem; */
  border-radius: 0.3rem;
  margin: 0.5rem;
`

const selectedStyles = css`
  background-color: ${props => props.theme.tertiaryColor};
  border-radius: 0.5rem;
  padding: 0.3rem;
  margin: 0.1rem;
`

const consumedStyles = css`
  background-color: ${props => props.theme.dangerColor + '9F'};
  color: ${props => props.theme.primaryLighter};
  border-radius: 0.5rem;
  padding: 0.3rem;
  margin: 0.1rem;

  cursor: not-allowed;

  :hover {
    cursor: not-allowed;
    text-decoration: none;
  }
`

const TeamListOptionContainer = styled.div`
  padding: 0.2rem;
  font-size: 1rem;
  text-align: justify;
  text-justify: inter-character;

  display: flex;
  flex-direction: column;
  align-items: center;

  :hover {
    cursor: pointer;
    text-decoration: underline;
  }

  ${props => props.selected ? selectedStyles : ''};
  ${props => props.consumed ? consumedStyles : ''};

  @media (max-width: 500px) {
    grid-column: span 1;
    width: 90vw;
  }
`

const TeamListTitleRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 2vw;

  @media (max-width: 500px) {
    grid-column: span 1;
    width: 90vw;
  }
`

const CollapseIcon = styled.span`
  font-size: 1.5rem;
  padding-left: 1rem;

  @media (min-width: 500px) {
    display: none;
  }

  i {
    cursor: pointer;
  }

`

const TeamList = styled.div`
  @media (max-width: 500px) {
    ${props => props.condensed ? 'display: none' : ''};
  }
`
const TeamListOption = (props) => {
    const {event, option, showSpread} = props;
    const thisTeam = event?.competitions[0].competitors.find(it => it.team.abbreviation === option.team.split(' ')[0]);
    const opponent = event?.competitions[0].competitors.find(it => it.team.abbreviation !== option.team.split(' ')[0]);

    return (
        <TeamListOptionContainer {...props}>
            <span> {option.team} {formatter.format(option.odds)} </span>
            {!!event
                ? <small>
                    {thisTeam.homeAway === 'home' ? 'vs' : '@'} {' '}
                    {opponent.team.abbreviation} {' '}
                    {opponent.team.name ?? 'Football Team'}
                </small>
                : null
            }
        </TeamListOptionContainer>
    )

}
const TeamsListPicker = (props) => {
    const {title, pick, setPick, options, showSpread, locked, usedPicks, eventsByTeam, message} = props;
    const [condensed, setCondensed] = useState(false);

    return (
        <TeamsListPickerViewport>
            <TeamListTitleRow onClick={() => setCondensed(!condensed)}>
                <h2 style={{position: 'sticky'}}>
                    {title}: {pick}
                </h2>
                <CollapseIcon>
                    {condensed
                        ? <i className="fa fa-chevron-down" aria-hidden="true"/>
                        : <i className="fa fa-chevron-up" aria-hidden="true"/>}
                </CollapseIcon>
            </TeamListTitleRow>
            {message
                ? <PickLevelErrorMessage> {message} </PickLevelErrorMessage>
                : null
            }
            <TeamList condensed={condensed}>
                {options.map((it, index) =>
                    <TeamListOption
                        key={index}
                        selected={pick === it.team}
                        consumed={usedPicks?.includes(it.team)}
                        showSpread={showSpread}
                        option={it}
                        event={eventsByTeam[it.team]}
                        onClick={() => (locked || usedPicks?.includes(it.team)) ? console.log('locked') : setPick(it.team)}>
                    </TeamListOption>)}
            </TeamList>

        </TeamsListPickerViewport>
    )
}

const PickSectionViewport = styled.div`

`

const PicksHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  grid-column: span 3;
  padding: 0 2vw;

  @media (max-width: 500px) {
    grid-column: span 1;
  }
`

const NoOptions = styled.div`
  grid-column: 1 / -1;
  display: grid;
  place-items: center center;
  font-size: 2rem;
`

const PicksTitleContainer = styled.div`

`

const PickLevelErrorMessage = styled.span`
  font-size: 1rem;
  color: ${props => props.theme.dangerColor};
`

const SubmitPicksContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: 500px) {
    flex-direction: column;
    align-items: end;
  }
`

const getAlreadyUsedPicks = (picks, activeWeek) => {
    let clonedPicks = Object.assign({}, picks);
    delete clonedPicks[activeWeek];

    const usedPicks = Object.values(clonedPicks).reduce((acc, val) => {
        return {
            win: [val.win, ...acc.win],
            lose: [val.lose, ...acc.lose],
            ats: [val.ats, ...acc.ats]
        }
    }, {win: [], lose: [], ats: []})

    return usedPicks;
}

const EventsContext = React.createContext({});

export const MakePicks = (props) => {
    const [toWin, setToWin] = useState(null);
    const [toLose, setToLose] = useState(null);
    const [toWinATS, setToWinATS] = useState(null);
    const [usedPicks, setUsedPicks] = useState({});
    const [status, setStatus] = useState('unsubmitted');
    const [message, setMessage] = useState(null);
    const [perPickMessages, setPerPickMessages] = useState({});
    const savePicksInFirestore = useFunctions().httpsCallable('savePicks');

    const {data: user} = useUser();

    const savePicks = async () => {
        const pickObject = {
            poolId: id,
            picks: {
                win: toWin,
                lose: toLose,
                ats: toWinATS
            }
        }

        setStatus('submitting');
        try {
            const result = await savePicksInFirestore(pickObject);
            setToWin(member?.picks?.[pool.activeWeek]?.win)
            setToLose(member?.picks?.[pool.activeWeek]?.lose)
            setToWinATS(member?.picks?.[pool.activeWeek]?.ats)
            setStatus('submitted');
            setPerPickMessages(result.data);
            setMessage('Picks were submitted!');
        } catch (error) {
            setStatus('error');
            setMessage(error.message);
        }
    }

    const {id} = useParams();

    const poolRef = useFirestore()
        .collection('pools')
        .doc(id);

    const {data: pool} = useFirestoreDocData(poolRef);
    const lockAtDate = pool.picksLockAt.toDate()
    const locked = lockAtDate.getTime() < Date.now();
    const options = pool.options?.[pool.activeWeek];

    const {isLoading, error, data: eventsByTeam} = useQuery('opponents', async () => {
        try {
            const response = await fetch(`https://site.api.espn.com/apis/site/v2/sports/football/nfl/scoreboard?week=${pool.activeWeek}`)
            if (!response.ok) {
                return {};
            }
            const data = await response.json();
            const entries = data?.events
                ?.flatMap(it => it?.competitions[0]
                    ?.competitors.map(c => [`${c.team.abbreviation} ${c.team.name ?? 'Football Team'}`, it]))
            return Object.fromEntries(entries ?? []);
        } catch (error) {
            return {};
        }
    }, {retry: 2})

    const memberRef = useFirestore()
        .collection('pools')
        .doc(id)
        .collection('members')
        .doc(user.uid)
        .collection('private')
        .doc('config');

    const {data: member} = useFirestoreDocData(memberRef);

    useEffect(() => {
        setToWin(member?.picks?.[pool.activeWeek]?.win)
        setToLose(member?.picks?.[pool.activeWeek]?.lose)
        setToWinATS(member?.picks?.[pool.activeWeek]?.ats)
    }, [member, pool])


    useEffect(() => {
        setUsedPicks(getAlreadyUsedPicks(member?.picks, pool.activeWeek));
    }, [member, pool])

    return (
        <PicksViewport>
            <PicksHeader>
                <PicksTitleContainer>
                    <h2> {locked ? 'Your' : null} Picks for Week {pool.activeWeek}</h2>
                    <h4> Picks {locked ? 'closed' : 'close'} at {lockAtDate.toLocaleDateString('en-US', {dateStyle: 'short'})} {lockAtDate.toLocaleTimeString('en-Us', {timeStyle: 'short'})} </h4>
                </PicksTitleContainer>
                {!!options
                    ? <SubmitPicksContainer>
                        {status === 'submitted'
                            ? <span> {message} </span>
                            : status === 'error'
                                ? <span> {message} </span>
                                : null}
                        {!locked
                            ? <TertiaryButton onClick={savePicks}>
                                {status === 'submitting'
                                    ? <i className="fas fa-spinner fa-spin"/>
                                    : 'Submit'
                                }
                            </TertiaryButton>
                            : null
                        }
                    </SubmitPicksContainer>
                    : null
                }
            </PicksHeader>
            {!!options
                ? <React.Fragment>
                    <EventsContext.Provider value={eventsByTeam}>
                        <TeamsListPicker eventsByTeam={eventsByTeam}
                                         usedPicks={usedPicks.win}
                                         locked={locked}
                                         options={options}
                                         pick={toWin}
                                         setPick={setToWin}
                                         message={perPickMessages?.win}
                                         title="To Win"/>
                        <TeamsListPicker eventsByTeam={eventsByTeam}
                                         usedPicks={usedPicks.lose}
                                         locked={locked}
                                         options={options}
                                         pick={toLose}
                                         setPick={setToLose}
                                         message={perPickMessages?.lose}
                                         title="To Lose"/>
                        <TeamsListPicker eventsByTeam={eventsByTeam}
                                         usedPicks={usedPicks.ats}
                                         locked={locked}
                                         options={options}
                                         showSpread
                                         pick={toWinATS}
                                         setPick={setToWinATS}
                                         message={perPickMessages?.ats}
                                         title="To Win ATS"/>
                    </EventsContext.Provider>
                </React.Fragment>
                : <NoOptions>
                    Lines have not been locked for this week, check back later
                </NoOptions>
            }
        </PicksViewport>
    )
}