import { useFirestore, useFirestoreDocData } from 'reactfire';
import styled from 'styled-components';
import { ButtonLink } from '../styles';

const PoolSummaryContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  background-color: ${props => props.theme.primaryLighter};
  padding: 1rem;
  border-radius: 0.3rem;
  margin: 0.5rem;
`

const PoolNameContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`

export const PoolSummary = (props) => {
    const {poolId} = props;

    const poolRef = useFirestore().collection('pools').doc(poolId);
    const {data: pool} = useFirestoreDocData(poolRef);

    return (
        <PoolSummaryContainer>
            <PoolNameContainer>
                <h2>{pool.name}</h2>
            </PoolNameContainer>
            <div>
              <ButtonLink to={`/pools/${poolId}`}> View Pool </ButtonLink>
              <ButtonLink to={`/pools/${poolId}/picks`}> Make Picks </ButtonLink>
            </div>
        </PoolSummaryContainer>
    )

}